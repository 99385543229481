<template>
    <div class="profile__detail">
        <h2>{{ newsItem.title }}</h2>
        <p v-html="newsItem.body"></p>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'NewsDateil',
    data() {
        return {
            user: null,
            newsItem: {},
        }
    },
    methods: {
        init(){
            let id = this.$route.params.id;
            let apiUrl = this.$store.getters.getApiUrl;
                axios
                .get(apiUrl + 'appNews/' + id)
                .then(response => {
                    this.newsItem = response.data;
                });
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
    .profile__detail {
        h2 {
            font-weight: 700;
            margin: 38px 0 16px;
        }

        hr {
            margin: 40px 0 34px;
        }

        small {
            display: flex;
            justify-content: center;
        }
    }
</style>